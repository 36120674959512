<template>
    <div class="ApprenticeDetail">
        <div class="top">
            <img src="@/assets/td.png" class="td-img" alt="" />
            <div class="title">我的徒弟</div>
        </div>
        <UserCard :user="myApprentice"></UserCard>

        <div class="apprentice-container">
            <div class="left">
                <div class="flex-alc">
                    <div
                        class="flex-alc column mr-62 click"
                        @click="switchTag(0)"
                    >
                        <div class="tag" :class="{ 'sel-tag': active == 0 }">
                            辅导计划
                        </div>
                        <div
                            class="line"
                            :class="{ 'sel-line': active == 0 }"
                        ></div>
                    </div>
                    <div class="flex-alc column click" @click="switchTag(1)">
                        <div class="tag" :class="{ 'sel-tag': active == 1 }">
                            师徒日志
                        </div>
                        <div
                            class="line"
                            :class="{ 'sel-line': active == 1 }"
                        ></div>
                    </div>
                </div>
                <div
                    class="add-plan click"
                    @click="addPlan"
                    v-if="myApprentice.graduation == 0"
                >
                    +<span class="plan-text">新增辅导计划</span>
                </div>
                <div class="task" v-show="active == 0">
                    <NoData
                        v-if="!coachList.length"
                        text="你还没有给徒弟安排计划哦~"
                    ></NoData>
                    <div
                        class="plan mb-27"
                        v-for="(item, index) in coachList"
                        :key="'plan' + index"
                    >
                        <div class="time">
                            {{
                                item.fmt_created
                                    .replace("-", "年")
                                    .replace("-", "月") + "日"
                            }}
                        </div>
                        <div
                            class="detail"
                            :class="{
                                click:
                                    !item.category ||
                                    (item.category && item.status),
                            }"
                            @click="
                                !item.category
                                    ? goCur(item.curriculum_info.id)
                                    : item.status
                                    ? goTaskDetail(item.id)
                                    : ''
                            "
                        >
                            <div class="flex-jsb">
                                <div class="flex-alc">
                                    <div
                                        class="type"
                                        :class="{ 'type-up': !item.category }"
                                    >
                                        {{ item.category ? "线下" : "线上" }}
                                    </div>
                                    <div class="desc one-line">
                                        师傅安排学习《{{ item.fmt_title }}》
                                    </div>
                                </div>
                                <div class="flex-alc" v-show="!item.category">
                                    <div class="jt">>></div>
                                </div>
                            </div>
                            <div class="mt-10 flex-jsb flex-ale">
                                <div class="flex-ale" v-show="!item.category">
                                    <img
                                        :src="item.curriculum_info.cover"
                                        alt=""
                                        class="cur-img mr-11"
                                    />
                                    <div class="flex-alc">
                                        <div class="fabulous mr-13">
                                            <img
                                                src="@/assets/dz.png"
                                                alt=""
                                                class="icon"
                                            />
                                            <div class="ml-5">
                                                {{
                                                    item.curriculum_info
                                                        .like_number
                                                }}
                                            </div>
                                        </div>
                                        <div class="fabulous">
                                            <img
                                                src="@/assets/pl.png"
                                                alt=""
                                                class="icon"
                                            />
                                            <div class="ml-5">
                                                {{
                                                    item.curriculum_info
                                                        .estimate_number
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-show="item.category"
                                    class="brief one-line"
                                >
                                    {{ item.desc }}
                                </div>
                                <div
                                    class="surplus"
                                    :class="{ complete: item.status }"
                                >
                                    {{
                                        item.status
                                            ? "已完成"
                                            : `剩余${item.fmt_days}天`
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="record" v-show="active == 1">
                    <NoData
                        v-if="!recordList.length"
                        text="目前没有日志信息~"
                    ></NoData>
                    <div
                        class="mb-27"
                        v-for="(item, index) in recordList"
                        :key="'record' + index"
                    >
                        <div class="time">
                            {{ item.fmt_created.replace("-", "月") + "日" }}
                        </div>
                        <div
                            class="desc"
                            :class="{ type: item.status }"
                            v-if="!item.message"
                        >
                            {{ item.status ? "线下" : "线上" }}辅导计划：<span
                                class="identity"
                                >{{ item.identity ? "徒弟" : "师傅" }}</span
                            >{{ item.identity ? "完成了" : "安排学习" }}《{{
                                item.content
                            }}》
                        </div>
                        <div class="desc" v-if="item.message">
                            {{ item.content }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="bottom">
                    <div class="flex-alc between mb-20">
                        <div
                            class="flex-alc column click"
                            @click="selRanking(0)"
                        >
                            <div
                                class="rankingText"
                                :style="{
                                    color: ranking == 0 ? '#000' : '#B7B7B7',
                                }"
                            >
                                TA的成长值
                            </div>
                        </div>
                        <div
                            class="flex-alc column click"
                            @click="selRanking(1)"
                        >
                            <div
                                class="rankingText"
                                :style="{
                                    color: ranking == 1 ? '#000' : '#B7B7B7',
                                }"
                            >
                                成长值记录
                            </div>
                        </div>
                    </div>

                    <div class="flex-alc column mt-20" v-show="ranking == 0">
                        <div class="re">
                            <img
                                src="@/assets/image_czz@2x.png"
                                class="ball"
                                alt=""
                            />
                            <div class="growth">{{ my_growth }}</div>
                        </div>
                        <div class="month mt-10 mb-15">
                            {{ `以下是${month}月榜单` }}
                        </div>
                    </div>

                    <div
                        class="flex-alc between pl-35 pr-35 mt-13 mb-36"
                        v-show="ranking == 0"
                    >
                        <div class="flex-alc column click" @click="selType(0)">
                            <div class="list">月榜</div>
                            <div
                                class="selList"
                                :class="{ opt0: typeRanking != 0 }"
                            ></div>
                        </div>
                        <div class="flex-alc column click" @click="selType(1)">
                            <div class="list">总榜</div>
                            <div
                                class="selList"
                                :class="{ opt0: typeRanking != 1 }"
                            ></div>
                        </div>
                    </div>

                    <div class="pl-13 pr-13">
                        <!-- 成长值 -->
                        <div
                            class="flex-alc between mb-23"
                            v-for="(item, index) in rankingList"
                            :key="'user' + index"
                            v-show="index < 3 && ranking == 0"
                        >
                            <div class="flex-alc">
                                <img
                                    :src="ImgList[index]"
                                    class="rankingImg mr-15"
                                />
                                <div class="text">{{ item.name }}</div>
                            </div>
                            <div class="text">
                                {{ ranking ? item.credit : item.amount }}
                            </div>
                        </div>
                        <!-- 记录 -->
                        <NoData
                            v-if="!growthRecord.length && ranking == 1"
                            text="目前还没有成长记录~"
                            :img="false"
                            :gray="true"
                        ></NoData>
                        <div
                            v-show="ranking == 1"
                            class="growth-record"
                            v-for="(item, index) in growthRecord"
                            :key="'gro' + index"
                        >
                            <div class="flex-alc between">
                                <div class="time">{{ item.fmt_created }}</div>
                                <div class="rate">+{{ item.score }}</div>
                            </div>
                            <div class="end">完成《{{ item.content }}》</div>
                        </div>
                    </div>

                    <div class="lookRanking click" @click="lookRanking">
                        查看全榜 >
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            :visible.sync="isAdd"
            :show-close="true"
            class="add-type"
            width="500px"
        >
            <div class="title">新增辅导计划</div>
            <el-divider></el-divider>
            <div class="type">请选择辅导计划类型</div>
            <div
                class="type-button click"
                @click="addType(index)"
                v-for="(item, index) in 2"
                :key="'type' + index"
            >
                <div class="add-icon">+</div>
                <div>{{ index == 0 ? "线上" : "线下" }}辅导计划</div>
            </div>
        </el-dialog>

        <el-dialog
            :visible.sync="showCoach"
            :show-close="true"
            class="add-coach"
            width="594px"
        >
            <div class="on-line" v-if="coachType == 0">
                <div class="title">新增线上辅导计划</div>
                <el-divider></el-divider>
                <div class="toWho mb-17">
                    您将新增以下辅导计划给徒弟<span class="fs-20 ml-5">{{
                        myApprentice.name
                    }}</span>
                </div>
                <div class="seacher mb-25">
                    <img
                        src="@/assets/ic_ss3@2x.png"
                        class="seacher-img"
                        alt=""
                    />
                    <input
                        type="text"
                        class="inp"
                        placeholder="搜索课程"
                        @keyup.enter="getCur()"
                        v-model="search"
                    />
                    <div class="seacher-button click" @click="getCur()"></div>
                </div>
                <!-- 课程分类 -->
                <div class="flex wid_100 pl-29 mb-15 mt-25">
                    <div class="leve-type mr-20">课程分类</div>
                    <div class="flex wrap">
                        <div
                            class="one-leve click mr-20"
                            :class="{ 'sel-one-leve': oneLeveActive == -1 }"
                            @click="switchOneType(-1)"
                        >
                            全部
                        </div>
                        <div
                            class="one-leve click mr-20 mb-10"
                            :class="{ 'sel-one-leve': oneLeveActive == index }"
                            v-for="(item, index) in typeList"
                            :key="'one-leve' + index"
                            @click="switchOneType(index)"
                        >
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <div
                    class="flex mb-10 pl-29 wid_100"
                    v-show="oneLeveActive != -1 && twoLeveList.length"
                >
                    <div class="leve-type mr-20">课程分类</div>
                    <div class="flex wrap">
                        <div
                            class="one-leve mr-20 click mb-10"
                            :class="{
                                'sel-one-leve click': twoLeveActive == index,
                            }"
                            v-for="(item, index) in twoLeveList"
                            :key="'two-leve' + index"
                            @click="switchTwoType(index)"
                        >
                            {{ item.name }}
                        </div>
                    </div>
                </div>

                <!-- 课程列表 -->
                <div class="wid_100">
                    <div
                        class="flex-alc between wrap cur-container"
                        v-show="curriculumList.length"
                    >
                        <Curriculum
                            v-for="(item, index) in curriculumList"
                            :key="'cur' + index"
                            class="mt-18"
                            :noClick="true"
                            @selCur="selCur"
                            :curriculum="item"
                            :imgSize="218"
                            :border="6"
                        ></Curriculum>
                    </div>
                </div>
                <!-- 已选择 -->
                <div
                    class="flex-alc mt-21 wid_100 pl-29 pr-30"
                    v-show="selCurObj.name"
                >
                    <div class="already-sel">已选择</div>
                    <div class="flex-alc sel-cur">
                        <div>{{ selCurObj.name }}</div>
                        <div class="click ml-10" @click="cancel">×</div>
                    </div>
                </div>

                <div class="wid_100 pl-29 pr-30 mt-20">
                    <div class="flex-alc between">
                        <div style="width: 45%">
                            <div class="tit">学习结束时间</div>
                            <el-date-picker
                                v-model="time"
                                type="date"
                                placeholder="选择学习结束时间"
                                class="mb-20"
                                value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                        </div>
                        <div style="width: 45%">
                            <div class="tit">测评时间</div>
                            <el-date-picker
                                v-model="eva_time"
                                type="date"
                                placeholder="选择测评时间"
                                class="mb-20"
                                value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="flex-alc between">
                        <div style="width: 45%">
                            <div class="tit">任务成长值</div>
                            <el-select
                                class="mb-20"
                                v-model="growth"
                                placeholder="请选择数值"
                            >
                                <el-option
                                    v-for="item in options"
                                    :key="'options' + item"
                                    :label="item"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div style="width: 45%">
                            <div class="tit">需求测评分数（分）</div>
                            <el-input
                                type="text"
                                placeholder="请输入分数"
                                v-model="grade"
                                show-word-limit
                                class="mb-20"
                            >
                            </el-input>
                        </div>
                    </div>
                    <div class="submit click" @click="publish">发布计划</div>
                </div>
            </div>
            <div class="off-line" v-if="coachType == 1">
                <div class="title">新增线下辅导计划</div>
                <el-divider></el-divider>
                <div class="from">
                    <div class="type">辅导计划标题</div>
                    <el-input
                        type="text"
                        placeholder="请输入标题"
                        v-model="title"
                        maxlength="30"
                        show-word-limit
                        class="mb-25"
                    >
                    </el-input>
                    <div class="type">辅导计划简介</div>
                    <el-input
                        type="textarea"
                        placeholder="请输入标题"
                        v-model="desc"
                        maxlength="200"
                        show-word-limit
                        :rows="5"
                        class="mb-25"
                    >
                    </el-input>
                    <div class="type">学习结束时间</div>

                    <el-date-picker
                        v-model="time"
                        type="date"
                        placeholder="选择学习结束时间"
                        class="mb-25"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <div class="type">任务成长值</div>
                    <el-select
                        class="mb-35"
                        v-model="growth"
                        placeholder="请选择数值"
                    >
                        <el-option
                            v-for="item in options"
                            :key="'options' + item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                    <div class="submit click" @click="publish">发布计划</div>
                </div>
            </div>
        </el-dialog>

        <Evaluate
            v-if="isEvaluate"
            :text="isEvaluate"
            :id="relate"
            title="评价徒弟"
            :name="myApprentice.name"
            @confirm="confirm"
        ></Evaluate>

        <EvaluateSuccess
            text="评价成功"
            v-if="isEvaluateSuccess"
        ></EvaluateSuccess>

        <!-- 查看徒弟线下任务完成 -->
        <el-dialog
            :visible.sync="showTask"
            :show-close="true"
            class="fill-task"
            width="648px"
        >
            <div class="title">线下任务完成详情</div>
            <el-divider></el-divider>
            <div class="wid_100 pr-40 pl-40 pb-40">
                <div class="task-title">辅导计划标题</div>
                <div class="task-success">完成《{{ offTask.fmt_title }}》</div>
                <div class="task-title">辅导计划简介</div>
                <div class="task-success">{{ offTask.explain }}</div>
                <div class="task-title">完成说明</div>
                <div class="task-success">{{ offTask.desc }}</div>
                <div class="task-title">图片</div>
                <div class="flex wrap mb-25">
                    <el-image
                        class="look-img"
                        :src="img"
                        v-for="(img, index) in offTask.picture_list"
                        :key="'img' + index"
                        :preview-src-list="offTask.picture_list"
                    >
                    </el-image>
                </div>
                <div class="subTask click" @click="showTask = false">确定</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import NoData from "@/components/NoData.vue";
    import Curriculum from "@/components/Curriculum.vue";
    import UserCard from "@/components/UserCard.vue";
    import Evaluate from "@/components/Evaluate.vue";
    import EvaluateSuccess from "@/components/EvaluateSuccess.vue";
    export default {
        name: "ApprenticeDetail",
        components: {
            UserCard,
            Evaluate,
            EvaluateSuccess,
            Curriculum,
            NoData,
        },
        data() {
            return {
                options: [5, 10, 15, 20],
                myApprentice: { department_info: {} }, //徒弟详情
                id: 0, //徒弟id
                relate: 0, //师徒关系id
                active: 0, //0 辅导计划 1 师徒日志
                coachList: [], //辅导计划列表
                ranking: 0, //0 排行 1 记录
                recordList: [], //师徒日志
                ranking: 0, //0 成长值 1 成长记录
                typeRanking: 0, //0 月榜  1 总榜
                month: 0, //月份
                my_growth: 0, //我的成长值
                rankingList: [], //排行列表
                ImgList: [
                    require("@/assets/jp.png"),
                    require("@/assets/yp.png"),
                    require("@/assets/tp.png"),
                ], //金银铜排行图
                growthRecord: [], //记录列表
                isEvaluate: "", //是否弹出评论
                isEvaluateSuccess: false, //是否评价成功
                isAdd: false, //辅导计划弹窗
                showCoach: false, //辅导计划弹窗
                coachType: 0, //0 线上辅导 1 线下辅导
                search: "", //搜索课程
                typeList: [], //分类列表
                twoLeveList: [], //二级分类列表
                oneLeveActive: -1, //一级课程分类下标
                twoLeveActive: 0, //二级课程分类下标
                curriculumList: [], //课程列表
                selCurObj: {}, //选择的课程
                grade: "", //测评分数
                eva_time: "", //测评时间

                title: "", //辅导标题
                desc: "", //辅导简介
                time: "", //完成时间
                growth: null, //任务成长值

                showTask: false, //显示徒弟任务完成详情弹窗
                offTask: {}, //任务详情
            };
        },

        async mounted() {
            this.id = this.$route.query.id || 0;
            this.getApprentice();
            this.getRanking();
        },

        methods: {
            //查看徒弟线下任务
            goTaskDetail(e) {
                this.showTask = true;
                this.$https
                    .get("/api/my_pupil/coach_detail", { id: e })
                    .then((res) => {
                        if (res.errcode) return;
                        this.offTask = res.data;
                    });
            },
            //取消选择的课程
            cancel() {
                this.selCurObj = "";
            },
            //选中课程
            selCur(e) {
                console.log(e.name);
                this.selCurObj = e;
            },
            //切换一级分类
            switchOneType(e) {
                this.oneLeveActive = e;
                this.twoLeveList = this.typeList[e]?.second;
                this.twoLeveActive = e == -1 ? -1 : 0;
                this.getCur();
            },
            //切换二级分类
            switchTwoType(e) {
                this.twoLeveActive = e;
                this.getCur();
            },
            //获取分类列表
            getTypeList() {
                this.$https.get("/api/cur/category/list").then((res) => {
                    this.typeList = res.data.list;
                });
            },
            //搜索课程
            getCur() {
                this.$https
                    .get("/api/cur/list", {
                        page: 1,
                        limit: 999,
                        search: this.search,
                        category:
                            this.oneLeveActive == -1
                                ? ""
                                : this.typeList[this.oneLeveActive].id,
                        second:
                            this.oneLeveActive == -1
                                ? ""
                                : this.typeList[this.oneLeveActive]?.second[
                                      this.twoLeveActive
                                  ]?.id,
                    })
                    .then((res) => {
                        this.curriculumList = res.data.list;
                    });
            },
            //发布
            publish() {
                if (!this.time) {
                    return this.$message.error("请选择学习结束时间");
                }
                if (!this.growth) {
                    return this.$message.error("请输入任务成长值");
                }
                if (this.coachType == 0) {
                    if (!this.selCurObj.id) {
                        return this.$message.error("请选择课程");
                    }
                }
                if (this.coachType == 1) {
                    if (!this.title) {
                        return this.$message.error("请输入标题");
                    }
                    if (!this.desc) {
                        return this.$message.error("请输入简介");
                    }
                }
                this.$https
                    .post("/api/my_pupil/tesks", {
                        category: this.coachType,
                        relate: this.relate,
                        time: this.time,
                        title: this.title,
                        desc: this.desc,
                        curriculum: this.selCurObj.id,
                        eva_time: this.eva_time,
                        grade: this.grade,
                        growth: this.growth,
                    })
                    .then(async (res) => {
                        if (res.errcode) return this.$message.error(res.msg);
                        this.showCoach = false;
                        this.time = "";
                        this.title = "";
                        this.desc = "";
                        this.selCurObj = "";
                        this.eva_time = "";
                        this.grade = "";
                        this.growth = null;
                        await this.getCoach();
                        await this.getRecord();
                    });
            },
            //辅导计划类型
            async addType(e) {
                this.coachType = e;
                this.showCoach = true;
                this.isAdd = false;
                await this.getTypeList();
                this.getCur();
            },
            //新增辅导计划
            addPlan() {
                this.isAdd = true;
            },
            //提交评价徒弟
            confirm(e) {
                this.$https
                    .post(`/api/my_pupil/evaluate`, {
                        id: this.relate,
                        score: e.score,
                        content: e.content,
                    })
                    .then((res) => {
                        if (res.errcode) return this.$message.error(res.msg);
                        this.isEvaluateSuccess = true;
                        this.isEvaluate = "";
                    });
            },
            //获取排行榜
            getRanking() {
                if (this.ranking == 0) {
                    this.$https
                        .get("/api/my_master/growth_rank", {
                            kind: this.typeRanking ? "total" : "month",
                            id: this.id,
                        })
                        .then((res) => {
                            this.rankingList = res.data.list;
                            this.my_growth = res.data.my_growth;
                            this.month = res.data.month;
                        });
                } else {
                    this.$https
                        .get("/api/my_master/growth_list", {
                            page: 1,
                            limit: 7,
                            id: this.id,
                        })
                        .then((res) => {
                            this.growthRecord = res.data.list;
                        });
                }
            },
            //查看全榜
            lookRanking() {
                this.$router.push({
                    path: "/myInfo/growth",
                    query: { ranking: this.id },
                });
            },
            //切换月、总榜
            selType(e) {
                this.typeRanking = e;
                this.getRanking();
            },
            //切换成长值和记录
            selRanking(e) {
                this.ranking = e;
                this.getRanking();
            },
            //获取日志
            getRecord() {
                this.$https
                    .post("/api/master_pupil/record", {
                        page: 1,
                        limit: 999,
                        relate: this.relate,
                    })
                    .then((res) => {
                        this.recordList = res.data.list;
                    });
            },
            //切换辅导计划/记录
            switchTag(e) {
                this.active = e;
            },
            //课程详情
            goCur(e) {
                this.$router.push({
                    path: "/CurriculumDetail",
                    query: { id: e },
                });
            },
            //获取辅导计划列表
            getCoach() {
                this.$https
                    .get("/api/my_pupil/coach", {
                        relate: this.relate,
                        page: 1,
                        limit: 999,
                    })
                    .then((res) => {
                        this.coachList = res.data.list;
                    });
            },
            //获取徒弟详情
            getApprentice() {
                this.$https
                    .get("/api/my_pupil/detail", { id: this.id })
                    .then(async (res) => {
                        if (res.errcode) return;
                        this.myApprentice = res.data.pupil;
                        this.relate = res.data.relate;

                        //评价徒弟
                        await this.$https
                            .get("/api/my_pupil/evaluate", { id: this.relate })
                            .then((res) => {
                                if (res.errcode) return;
                                this.isEvaluate = `${res.data.this_month}/${
                                    res.data.this_day < 10
                                        ? "0" + res.data.this_day
                                        : res.data.this_day
                                }`;
                            });

                        this.getCoach();
                        this.getRecord();
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -o-appearance: none !important;
    -ms-appearance: none !important;
    appearance: none !important;
    margin: 0;
}
input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    -o-appearance: textfield;
    -ms-appearance: textfield;
    appearance: textfield;
}
.ApprenticeDetail {
    .top {
        display: flex;
        align-items: center;
        border-radius: 6px;
        background: white;
        padding: 21px 24px;
        margin-bottom: 20px;
        .td-img {
            width: 29px;
            height: 30px;
            margin-right: 12px;
        }
        .title {
            font-size: 18px;
            font-weight: 500;
            color: #2d2d2d;
        }
    }
    .apprentice-container {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .left {
            width: 633px;
            background: white;
            padding: 18px 30px;
            height: max-content;
            .tag {
                font-size: 14px;
                font-weight: 400;
                color: #a1a1a1;
            }
            .sel-tag {
                font-size: 16px;
                font-weight: 600;
                color: #e41414;
            }
            .line {
                width: 43px;
                height: 4px;
                border-radius: 4px;
                margin-top: 9px;
            }
            .sel-line {
                background: linear-gradient(180deg, #ff3764, #ff2604);
            }
            .add-plan {
                border: 1px solid #d5d5d5;
                border-radius: 5px;
                display: flex;
                align-items: center;
                font-size: 22px;
                font-weight: 400;
                color: #2d2d2d;
                padding: 4px 13px;
                margin-top: 20px;
                .plan-text {
                    margin-left: 10px;
                    font-size: 16px;
                }
            }
            .task::-webkit-scrollbar {
                opacity: 0;
            }
            .record::-webkit-scrollbar {
                opacity: 0;
            }
            .task {
                margin: 25px 0px;
                height: 480px;
                overflow-y: scroll;
                .plan {
                    .time {
                        font-size: 14px;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: 10px;
                    }
                    .detail {
                        width: 100%;
                        padding: 11px 8px;
                        background: #f6f6f6;
                        border-radius: 5px;
                        .type {
                            border: 1px solid #21ad29;
                            padding: 1px 8px;
                            border-radius: 7px 7px 7px 0px;
                            margin-right: 12px;
                            font-size: 12px;
                            font-weight: 400;
                            color: #21ad29;
                            min-width: 45px;
                        }
                        .desc {
                            font-size: 14px;
                            font-weight: 400;
                            color: #000000;
                        }
                        .type-up {
                            color: #f32929;
                            border: 1px solid #f32929;
                        }
                        .surplus {
                            background: #fcdddd;
                            padding: 1px 8px;
                            font-size: 12px;
                            font-weight: 400;
                            color: #f32929;
                            border-radius: 10px;
                            margin-left: 10px;
                            height: max-content;
                        }
                        .complete {
                            color: #21ad29;
                            background: #e6ffe7;
                        }
                        .jt {
                            font-size: 14px;
                            font-weight: 400;
                            color: #000000;
                            margin-left: 10px;
                        }
                        .brief {
                            font-size: 14px;
                            font-weight: 400;
                            color: #afafaf;
                            margin-left: 52px;
                            width: 380px;
                            -webkit-line-clamp: 5;
                        }
                        .cur-img {
                            width: 218px;
                            height: 116px;
                        }
                        .fabulous {
                            font-size: 11px;
                            font-weight: 400;
                            color: #797979;
                            display: flex;
                            align-items: center;

                            .icon {
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                }
            }
            .record {
                padding: 25px 0;
                height: 450px;
                overflow-y: scroll;
                .time {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000000;
                    margin-bottom: 5px;
                }
                .desc {
                    font-size: 14px;
                    font-weight: 400;
                    color: #000000;
                    margin-top: 6px;
                    .identity {
                        color: red;
                    }
                }
            }
        }
        .right {
            .bottom {
                width: 318px;
                padding: 18px 22px;
                background: #ffffff;
                border-radius: 4px 4px 15px 15px;

                .rankingText {
                    font-size: 16px;
                    font-weight: 500;
                }
                .ranking {
                    width: 43px;
                    height: 4px;
                    background: linear-gradient(180deg, #ff3764, #ff2604);
                    border-radius: 4px;
                }

                .ball {
                    width: 111px;
                    height: 111px;
                }
                .number {
                    font-size: 40px;
                    font-weight: 500;
                    color: #e41414;
                }
                .my-rate {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000000;
                }
                .month {
                    font-size: 16px;
                    font-weight: 400;
                    color: #000000;
                }
                .growth {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 40px;
                    font-weight: 500;
                    color: #ffffff;
                }

                .list {
                    font-size: 18px;
                    font-weight: 500;
                    color: #000000;
                }

                .selList {
                    width: 44px;
                    height: 3px;
                    background: linear-gradient(180deg, #ff3764, #ff2604);
                    border-radius: 4px;
                    margin-top: 5px;
                }

                .text {
                    font-size: 16px;
                    font-weight: 500;
                    color: #000000;
                }
                .rankingImg {
                    width: 23px;
                    height: 29px;
                }
                .lookRanking {
                    font-size: 14px;
                    font-weight: 400;
                    color: #929292;
                    text-align: center;
                }
                .growth-record {
                    margin-bottom: 22px;
                    .time {
                        font-size: 17px;
                        font-weight: 500;
                        color: #000;
                    }
                    .rate {
                        font-size: 20px;
                        font-weight: 500;
                        color: #fe3040;
                    }
                    .end {
                        font-size: 17px;
                        font-weight: 400;
                        color: #000000;
                    }
                }
            }
        }
    }
    .add-type {
        /deep/.el-dialog {
            padding: 0;
            .el-dialog__header {
                padding: 0;
            }
            .el-dialog__body {
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 70px;
                .title {
                    font-size: 18px;
                    font-weight: 500;
                    color: #000000;
                    padding: 19px 0;
                }
                .el-divider--horizontal {
                    margin-top: 0;
                    margin-bottom: 24px;
                }
                .type {
                    font-size: 16px;
                    font-weight: 400;
                    color: #2d2d2d;
                    margin-bottom: 53px;
                }
                .type-button {
                    width: 219px;
                    height: 50px;
                    background: #f6f6f6;
                    border-radius: 32px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #2d2d2d;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 20px;
                    .add-icon {
                        width: 18px;
                        height: 18px;
                        color: white;
                        background: linear-gradient(180deg, #ff3764, #ff2604);
                        display: flex;
                        align-items: center;
                        border-radius: 100%;
                        justify-content: center;
                        line-height: 1px;
                        margin-right: 7px;
                    }
                }
            }
        }
    }
    .add-coach::-webkit-scrollbar {
        opacity: 0;
    }
    .add-coach {
        /deep/.el-dialog {
            padding: 0;
            border-radius: 10px;
            .el-dialog__header {
                padding: 0;
            }
            .el-dialog__body {
                padding: 0;
                padding-bottom: 35px;
                .off-line {
                    .title {
                        font-size: 18px;
                        font-weight: 500;
                        color: #000000;
                        padding: 19px 0;
                        text-align: center;
                    }
                    .el-divider--horizontal {
                        margin-top: 0;
                        margin-bottom: 24px;
                    }
                    .from {
                        padding: 0 45px;
                        .type {
                            font-size: 14px;
                            font-weight: 500;
                            color: #2d2d2d;
                            margin-bottom: 11px;
                        }
                        .el-textarea .el-textarea__inner,
                        .el-input .el-input__inner {
                            background: #f6f6f6;
                            border: 1px solid #d5d5d5;
                        }
                        .el-textarea .el-input__count,
                        .el-input .el-input__count .el-input__count-inner {
                            background: #f6f6f6;
                        }
                        .el-date-editor.el-input {
                            width: 100%;
                        }
                        .submit {
                            width: 130px;
                            height: 32px;
                            background: linear-gradient(
                                180deg,
                                #ff3764,
                                #ff2604
                            );
                            border-radius: 17px;
                            font-size: 14px;
                            font-weight: 500;
                            color: #ffffff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: auto;
                        }
                    }
                }
                .on-line {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .title {
                        font-size: 18px;
                        font-weight: 500;
                        color: #000000;
                        padding: 19px 0;
                        text-align: center;
                    }
                    .el-divider--horizontal {
                        margin-top: 0;
                        margin-bottom: 19px;
                    }
                    .toWho {
                        font-size: 14px;
                        font-weight: 500;
                        color: #2d2d2d;
                    }
                    .seacher {
                        width: 342px;
                        height: 48px;
                        position: relative;
                        margin: auto;
                        .seacher-img {
                            width: 100%;
                            height: 100%;
                        }
                        .inp {
                            position: absolute;
                            left: 15px;
                            top: 0;
                            width: 280px;
                            height: 48px;
                            outline: none;
                            border: none;
                            background: #fff3f3;
                        }
                        .seacher-button {
                            width: 48px;
                            height: 48px;
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                    }
                    .leve-type {
                        font-size: 14px;
                        min-width: 65px;
                        font-weight: 500;
                        color: #2d2d2d;
                    }
                    .one-leve {
                        font-size: 14px;
                        font-weight: 400;
                        color: #000;
                    }
                    .sel-one-leve {
                        color: #d30015;
                    }
                    .cur-container::-webkit-scrollbar {
                        opacity: 0;
                    }
                    .cur-container {
                        width: 534px;
                        height: 245px;
                        padding: 0 13px 13px 26px;
                        margin-left: 29px;
                        overflow-y: scroll;
                        background: #f6f6f6;
                    }
                    .already-sel {
                        min-width: 45px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #000000;
                    }
                    .sel-cur {
                        opacity: 0.36;
                        border: 1px dashed #ff365c;
                        border-radius: 13px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #ff314a;
                        padding: 3px 8px;
                        margin-left: 14px;
                    }
                    .el-textarea .el-textarea__inner,
                    .el-input .el-input__inner {
                        background: #f6f6f6;
                        border: 1px solid #d5d5d5;
                    }
                    .el-textarea .el-input__count,
                    .el-input .el-input__count .el-input__count-inner {
                        background: #f6f6f6;
                    }
                    .el-date-editor.el-input {
                        width: 100%;
                    }
                    .tit {
                        font-size: 14px;
                        font-weight: 500;
                        color: #2d2d2d;
                        margin-bottom: 11px;
                    }
                    .submit {
                        width: 148px;
                        height: 36px;
                        background: linear-gradient(180deg, #ff3764, #ff2604);
                        border-radius: 18px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 10px auto;
                    }
                }
            }
        }
    }
    .fill-task {
        /deep/.el-dialog {
            border-radius: 10px;
            .el-dialog__header {
                display: none;
            }
            .el-dialog__body {
                padding: 0;
                .title {
                    font-size: 18px;
                    font-weight: 500;
                    color: #000000;
                    padding: 19px 0;
                    text-align: center;
                }
                .el-divider--horizontal {
                    margin-top: 0;
                    margin-bottom: 19px;
                    height: 2px;
                }
                .task-title {
                    font-size: 14px;
                    font-weight: 500;
                    color: #2d2d2d;
                    margin-bottom: 7px;
                    .num {
                        font-size: 12px;
                        font-weight: 400;
                        color: #b2b2b2;
                    }
                }
                .look-img {
                    width: 102px;
                    height: 102px;
                    margin-bottom: 10px;
                    margin-right: 10px;
                }
                .task-success {
                    font-size: 14px;
                    font-weight: 400;
                    color: #858585;
                    margin-bottom: 25px;
                }
                .subTask {
                    width: 130px;
                    height: 32px;
                    background: linear-gradient(180deg, #ff3764, #ff2604);
                    border-radius: 17px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #ffffff;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
</style>